export const clientByCognitoId = /* GraphQL */ `
  query ClientByCognitoId(
    $cognitoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientByCognitoId(
      cognitoId: $cognitoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoId
        prenom
        nom
        sanboxLemonwayId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const formateurByCognitoId = /* GraphQL */ `
  query FormateurByCognitoId(
    $cognitoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelFormateurFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formateurByCognitoId(
      cognitoId: $cognitoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentHtml
        cognitoId
        prenom
        nom
        url
        state
        imageId
        pixelCropWidth
        pixelCropHeight
        pixelCropX
        pixelCropY
        hasPhoto
        photoHash
        note
        nbAvis
        youtube
        linkedin
        intro
        markdown
        hashList
        publicationList
        specialites
        sanboxLemonwayId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;