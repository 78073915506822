/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompanyAccount = /* GraphQL */ `
  mutation CreateCompanyAccount(
    $input: CreateCompanyAccountInput!
    $condition: ModelCompanyAccountConditionInput
  ) {
    createCompanyAccount(input: $input, condition: $condition) {
      id
      fullName
      nbMaxMembers
      category
      memberShips {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyAccount = /* GraphQL */ `
  mutation UpdateCompanyAccount(
    $input: UpdateCompanyAccountInput!
    $condition: ModelCompanyAccountConditionInput
  ) {
    updateCompanyAccount(input: $input, condition: $condition) {
      id
      fullName
      nbMaxMembers
      category
      memberShips {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyAccount = /* GraphQL */ `
  mutation DeleteCompanyAccount(
    $input: DeleteCompanyAccountInput!
    $condition: ModelCompanyAccountConditionInput
  ) {
    deleteCompanyAccount(input: $input, condition: $condition) {
      id
      fullName
      nbMaxMembers
      category
      memberShips {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMemberShip = /* GraphQL */ `
  mutation CreateMemberShip(
    $input: CreateMemberShipInput!
    $condition: ModelmemberShipConditionInput
  ) {
    createMemberShip(input: $input, condition: $condition) {
      id
      formateurId
      companyAccountId
      administratorPrivileges
      accountantPrivileges
      editorPrivileges
      createdAt
      updatedAt
    }
  }
`;
export const updateMemberShip = /* GraphQL */ `
  mutation UpdateMemberShip(
    $input: UpdateMemberShipInput!
    $condition: ModelmemberShipConditionInput
  ) {
    updateMemberShip(input: $input, condition: $condition) {
      id
      formateurId
      companyAccountId
      administratorPrivileges
      accountantPrivileges
      editorPrivileges
      createdAt
      updatedAt
    }
  }
`;
export const deleteMemberShip = /* GraphQL */ `
  mutation DeleteMemberShip(
    $input: DeleteMemberShipInput!
    $condition: ModelmemberShipConditionInput
  ) {
    deleteMemberShip(input: $input, condition: $condition) {
      id
      formateurId
      companyAccountId
      administratorPrivileges
      accountantPrivileges
      editorPrivileges
      createdAt
      updatedAt
    }
  }
`;
export const createPromoCode = /* GraphQL */ `
  mutation CreatePromoCode(
    $input: CreatePromoCodeInput!
    $condition: ModelpromoCodeConditionInput
  ) {
    createPromoCode(input: $input, condition: $condition) {
      id
      category
      codeChain
      inceptionDate
      createdAt
      updatedAt
    }
  }
`;
export const updatePromoCode = /* GraphQL */ `
  mutation UpdatePromoCode(
    $input: UpdatePromoCodeInput!
    $condition: ModelpromoCodeConditionInput
  ) {
    updatePromoCode(input: $input, condition: $condition) {
      id
      category
      codeChain
      inceptionDate
      createdAt
      updatedAt
    }
  }
`;
export const deletePromoCode = /* GraphQL */ `
  mutation DeletePromoCode(
    $input: DeletePromoCodeInput!
    $condition: ModelpromoCodeConditionInput
  ) {
    deletePromoCode(input: $input, condition: $condition) {
      id
      category
      codeChain
      inceptionDate
      createdAt
      updatedAt
    }
  }
`;
export const createFormateur = /* GraphQL */ `
  mutation CreateFormateur(
    $input: CreateFormateurInput!
    $condition: ModelFormateurConditionInput
  ) {
    createFormateur(input: $input, condition: $condition) {
      id
      documentHtml
      cognitoId
      prenom
      isFollowed {
        nextToken
      }
      nom
      url
      state
      imageId
      pixelCropWidth
      pixelCropHeight
      pixelCropX
      pixelCropY
      hasPhoto
      photoHash
      note
      nbAvis
      youtube
      linkedin
      intro
      markdown
      hashList
      attributes {
        nextToken
      }
      cycles {
        nextToken
      }
      publications {
        nextToken
      }
      publicationList
      documentPublications {
        nextToken
      }
      mailingList {
        nextToken
      }
      specialites
      sanboxLemonwayId
      lemonwayId
      createdAt
      updatedAt
    }
  }
`;
export const updateFormateur = /* GraphQL */ `
  mutation UpdateFormateur(
    $input: UpdateFormateurInput!
    $condition: ModelFormateurConditionInput
  ) {
    updateFormateur(input: $input, condition: $condition) {
      id
      documentHtml
      cognitoId
      prenom
      isFollowed {
        nextToken
      }
      nom
      url
      state
      imageId
      pixelCropWidth
      pixelCropHeight
      pixelCropX
      pixelCropY
      hasPhoto
      photoHash
      note
      nbAvis
      youtube
      linkedin
      intro
      markdown
      hashList
      attributes {
        nextToken
      }
      cycles {
        nextToken
      }
      publications {
        nextToken
      }
      publicationList
      documentPublications {
        nextToken
      }
      mailingList {
        nextToken
      }
      specialites
      sanboxLemonwayId
      lemonwayId
      createdAt
      updatedAt
    }
  }
`;
export const deleteFormateur = /* GraphQL */ `
  mutation DeleteFormateur(
    $input: DeleteFormateurInput!
    $condition: ModelFormateurConditionInput
  ) {
    deleteFormateur(input: $input, condition: $condition) {
      id
      documentHtml
      cognitoId
      prenom
      isFollowed {
        nextToken
      }
      nom
      url
      state
      imageId
      pixelCropWidth
      pixelCropHeight
      pixelCropX
      pixelCropY
      hasPhoto
      photoHash
      note
      nbAvis
      youtube
      linkedin
      intro
      markdown
      hashList
      attributes {
        nextToken
      }
      cycles {
        nextToken
      }
      publications {
        nextToken
      }
      publicationList
      documentPublications {
        nextToken
      }
      mailingList {
        nextToken
      }
      specialites
      sanboxLemonwayId
      lemonwayId
      createdAt
      updatedAt
    }
  }
`;
export const createClientAttribute = /* GraphQL */ `
  mutation CreateClientAttribute(
    $input: CreateClientAttributeInput!
    $condition: ModelClientAttributeConditionInput
  ) {
    createClientAttribute(input: $input, condition: $condition) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
    }
  }
`;
export const updateClientAttribute = /* GraphQL */ `
  mutation UpdateClientAttribute(
    $input: UpdateClientAttributeInput!
    $condition: ModelClientAttributeConditionInput
  ) {
    updateClientAttribute(input: $input, condition: $condition) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientAttribute = /* GraphQL */ `
  mutation DeleteClientAttribute(
    $input: DeleteClientAttributeInput!
    $condition: ModelClientAttributeConditionInput
  ) {
    deleteClientAttribute(input: $input, condition: $condition) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
    }
  }
`;
export const createFormateurAttribute = /* GraphQL */ `
  mutation CreateFormateurAttribute(
    $input: CreateFormateurAttributeInput!
    $condition: ModelFormateurAttributeConditionInput
  ) {
    createFormateurAttribute(input: $input, condition: $condition) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
    }
  }
`;
export const updateFormateurAttribute = /* GraphQL */ `
  mutation UpdateFormateurAttribute(
    $input: UpdateFormateurAttributeInput!
    $condition: ModelFormateurAttributeConditionInput
  ) {
    updateFormateurAttribute(input: $input, condition: $condition) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
    }
  }
`;
export const deleteFormateurAttribute = /* GraphQL */ `
  mutation DeleteFormateurAttribute(
    $input: DeleteFormateurAttributeInput!
    $condition: ModelFormateurAttributeConditionInput
  ) {
    deleteFormateurAttribute(input: $input, condition: $condition) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
    }
  }
`;
export const createCredit = /* GraphQL */ `
  mutation CreateCredit(
    $input: CreateCreditInput!
    $condition: ModelCreditConditionInput
  ) {
    createCredit(input: $input, condition: $condition) {
      id
      clientId
      category
      amount
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const updateCredit = /* GraphQL */ `
  mutation UpdateCredit(
    $input: UpdateCreditInput!
    $condition: ModelCreditConditionInput
  ) {
    updateCredit(input: $input, condition: $condition) {
      id
      clientId
      category
      amount
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteCredit = /* GraphQL */ `
  mutation DeleteCredit(
    $input: DeleteCreditInput!
    $condition: ModelCreditConditionInput
  ) {
    deleteCredit(input: $input, condition: $condition) {
      id
      clientId
      category
      amount
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      cognitoId
      prenom
      follows {
        nextToken
      }
      likes {
        nextToken
      }
      credits {
        nextToken
      }
      newsletterConsentDate
      suscribeStatus
      CGUVersion
      nom
      sanboxLemonwayId
      lemonwayId
      TVAid
      emailId
      email {
        id
        clientId
        email
        formateurOwnerId
        pekoiaClient
        source
        suscribedStatus
        createdAt
        updatedAt
      }
      attributes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      cognitoId
      prenom
      follows {
        nextToken
      }
      likes {
        nextToken
      }
      credits {
        nextToken
      }
      newsletterConsentDate
      suscribeStatus
      CGUVersion
      nom
      sanboxLemonwayId
      lemonwayId
      TVAid
      emailId
      email {
        id
        clientId
        email
        formateurOwnerId
        pekoiaClient
        source
        suscribedStatus
        createdAt
        updatedAt
      }
      attributes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      cognitoId
      prenom
      follows {
        nextToken
      }
      likes {
        nextToken
      }
      credits {
        nextToken
      }
      newsletterConsentDate
      suscribeStatus
      CGUVersion
      nom
      sanboxLemonwayId
      lemonwayId
      TVAid
      emailId
      email {
        id
        clientId
        email
        formateurOwnerId
        pekoiaClient
        source
        suscribedStatus
        createdAt
        updatedAt
      }
      attributes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEmailTag = /* GraphQL */ `
  mutation CreateEmailTag(
    $input: CreateEmailTagInput!
    $condition: ModelEmailTagConditionInput
  ) {
    createEmailTag(input: $input, condition: $condition) {
      id
      label
      createdAt
      updatedAt
    }
  }
`;
export const updateEmailTag = /* GraphQL */ `
  mutation UpdateEmailTag(
    $input: UpdateEmailTagInput!
    $condition: ModelEmailTagConditionInput
  ) {
    updateEmailTag(input: $input, condition: $condition) {
      id
      label
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmailTag = /* GraphQL */ `
  mutation DeleteEmailTag(
    $input: DeleteEmailTagInput!
    $condition: ModelEmailTagConditionInput
  ) {
    deleteEmailTag(input: $input, condition: $condition) {
      id
      label
      createdAt
      updatedAt
    }
  }
`;
export const createEmail = /* GraphQL */ `
  mutation CreateEmail(
    $input: CreateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    createEmail(input: $input, condition: $condition) {
      id
      clientId
      email
      formateurOwnerId
      pekoiaClient
      source
      suscribedStatus
      tags {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEmail = /* GraphQL */ `
  mutation UpdateEmail(
    $input: UpdateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    updateEmail(input: $input, condition: $condition) {
      id
      clientId
      email
      formateurOwnerId
      pekoiaClient
      source
      suscribedStatus
      tags {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmail = /* GraphQL */ `
  mutation DeleteEmail(
    $input: DeleteEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    deleteEmail(input: $input, condition: $condition) {
      id
      clientId
      email
      formateurOwnerId
      pekoiaClient
      source
      suscribedStatus
      tags {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      eventId
      key
      question
      grade
      comment
      createdAt
      updatedAt
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      eventId
      key
      question
      grade
      comment
      createdAt
      updatedAt
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      eventId
      key
      question
      grade
      comment
      createdAt
      updatedAt
    }
  }
`;
export const createPublication = /* GraphQL */ `
  mutation CreatePublication(
    $input: CreatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    createPublication(input: $input, condition: $condition) {
      id
      index
      formateurId
      type
      imageId
      imageExt
      imageTitle
      url
      ogTitle
      ogImage
      createdAt
      updatedAt
    }
  }
`;
export const updatePublication = /* GraphQL */ `
  mutation UpdatePublication(
    $input: UpdatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    updatePublication(input: $input, condition: $condition) {
      id
      index
      formateurId
      type
      imageId
      imageExt
      imageTitle
      url
      ogTitle
      ogImage
      createdAt
      updatedAt
    }
  }
`;
export const deletePublication = /* GraphQL */ `
  mutation DeletePublication(
    $input: DeletePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    deletePublication(input: $input, condition: $condition) {
      id
      index
      formateurId
      type
      imageId
      imageExt
      imageTitle
      url
      ogTitle
      ogImage
      createdAt
      updatedAt
    }
  }
`;
export const createEventsLogs = /* GraphQL */ `
  mutation CreateEventsLogs(
    $input: CreateEventsLogsInput!
    $condition: ModelEventsLogsConditionInput
  ) {
    createEventsLogs(input: $input, condition: $condition) {
      id
      type
      value
      key
      handled
      createdAt
      updatedAt
    }
  }
`;
export const updateEventsLogs = /* GraphQL */ `
  mutation UpdateEventsLogs(
    $input: UpdateEventsLogsInput!
    $condition: ModelEventsLogsConditionInput
  ) {
    updateEventsLogs(input: $input, condition: $condition) {
      id
      type
      value
      key
      handled
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventsLogs = /* GraphQL */ `
  mutation DeleteEventsLogs(
    $input: DeleteEventsLogsInput!
    $condition: ModelEventsLogsConditionInput
  ) {
    deleteEventsLogs(input: $input, condition: $condition) {
      id
      type
      value
      key
      handled
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      modelId
      isLinkedToModel
      formateurId
      cycleId
      isLiked {
        nextToken
      }
      organisateurId
      documentId
      state
      title1
      title2
      langue
      categories
      categoriesCode
      niveau
      prix
      date
      duree
      duration
      linkUrl
      type
      urls
      format
      nbMax
      nbReserved
      attendingNb
      outil
      outilUrl
      tags
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      eTagProcess
      htmlText {
        id
        modelId
        content
        HtmlCategory
        createdAt
        updatedAt
      }
      address
      lat
      lng
      password
      liveStatus
      replayCategory
      replayKey
      replayWeTransferUrl
      replayStatus
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      modelId
      isLinkedToModel
      formateurId
      cycleId
      isLiked {
        nextToken
      }
      organisateurId
      documentId
      state
      title1
      title2
      langue
      categories
      categoriesCode
      niveau
      prix
      date
      duree
      duration
      linkUrl
      type
      urls
      format
      nbMax
      nbReserved
      attendingNb
      outil
      outilUrl
      tags
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      eTagProcess
      htmlText {
        id
        modelId
        content
        HtmlCategory
        createdAt
        updatedAt
      }
      address
      lat
      lng
      password
      liveStatus
      replayCategory
      replayKey
      replayWeTransferUrl
      replayStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      modelId
      isLinkedToModel
      formateurId
      cycleId
      isLiked {
        nextToken
      }
      organisateurId
      documentId
      state
      title1
      title2
      langue
      categories
      categoriesCode
      niveau
      prix
      date
      duree
      duration
      linkUrl
      type
      urls
      format
      nbMax
      nbReserved
      attendingNb
      outil
      outilUrl
      tags
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      eTagProcess
      htmlText {
        id
        modelId
        content
        HtmlCategory
        createdAt
        updatedAt
      }
      address
      lat
      lng
      password
      liveStatus
      replayCategory
      replayKey
      replayWeTransferUrl
      replayStatus
      createdAt
      updatedAt
    }
  }
`;
export const createCycle = /* GraphQL */ `
  mutation CreateCycle(
    $input: CreateCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    createCycle(input: $input, condition: $condition) {
      id
      formateurId
      title
      status
      replayStatus
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      nbMax
      attendingNb
      prix
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCycle = /* GraphQL */ `
  mutation UpdateCycle(
    $input: UpdateCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    updateCycle(input: $input, condition: $condition) {
      id
      formateurId
      title
      status
      replayStatus
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      nbMax
      attendingNb
      prix
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCycle = /* GraphQL */ `
  mutation DeleteCycle(
    $input: DeleteCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    deleteCycle(input: $input, condition: $condition) {
      id
      formateurId
      title
      status
      replayStatus
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      nbMax
      attendingNb
      prix
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createStates = /* GraphQL */ `
  mutation CreateStates(
    $input: CreateStatesInput!
    $condition: ModelStatesConditionInput
  ) {
    createStates(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateStates = /* GraphQL */ `
  mutation UpdateStates(
    $input: UpdateStatesInput!
    $condition: ModelStatesConditionInput
  ) {
    updateStates(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteStates = /* GraphQL */ `
  mutation DeleteStates(
    $input: DeleteStatesInput!
    $condition: ModelStatesConditionInput
  ) {
    deleteStates(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;
export const createFollows = /* GraphQL */ `
  mutation CreateFollows(
    $input: CreateFollowsInput!
    $condition: ModelFollowsConditionInput
  ) {
    createFollows(input: $input, condition: $condition) {
      id
      clientId
      formateurId
      createdAt
      updatedAt
    }
  }
`;
export const updateFollows = /* GraphQL */ `
  mutation UpdateFollows(
    $input: UpdateFollowsInput!
    $condition: ModelFollowsConditionInput
  ) {
    updateFollows(input: $input, condition: $condition) {
      id
      clientId
      formateurId
      createdAt
      updatedAt
    }
  }
`;
export const deleteFollows = /* GraphQL */ `
  mutation DeleteFollows(
    $input: DeleteFollowsInput!
    $condition: ModelFollowsConditionInput
  ) {
    deleteFollows(input: $input, condition: $condition) {
      id
      clientId
      formateurId
      createdAt
      updatedAt
    }
  }
`;
export const createLikes = /* GraphQL */ `
  mutation CreateLikes(
    $input: CreateLikesInput!
    $condition: ModelLikesConditionInput
  ) {
    createLikes(input: $input, condition: $condition) {
      id
      clientId
      eventId
      createdAt
      updatedAt
    }
  }
`;
export const updateLikes = /* GraphQL */ `
  mutation UpdateLikes(
    $input: UpdateLikesInput!
    $condition: ModelLikesConditionInput
  ) {
    updateLikes(input: $input, condition: $condition) {
      id
      clientId
      eventId
      createdAt
      updatedAt
    }
  }
`;
export const deleteLikes = /* GraphQL */ `
  mutation DeleteLikes(
    $input: DeleteLikesInput!
    $condition: ModelLikesConditionInput
  ) {
    deleteLikes(input: $input, condition: $condition) {
      id
      clientId
      eventId
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      title
      clientId
      eventId
      eventImageKey
      eventImageETag300
      isReplay
      date
      eventDate
      expiryDate
      state
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      title
      clientId
      eventId
      eventImageKey
      eventImageETag300
      isReplay
      date
      eventDate
      expiryDate
      state
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      title
      clientId
      eventId
      eventImageKey
      eventImageETag300
      isReplay
      date
      eventDate
      expiryDate
      state
      createdAt
      updatedAt
    }
  }
`;
export const createOrganisateur = /* GraphQL */ `
  mutation CreateOrganisateur(
    $input: CreateOrganisateurInput!
    $condition: ModelOrganisateurConditionInput
  ) {
    createOrganisateur(input: $input, condition: $condition) {
      id
      nom
      publications {
        nextToken
      }
      note
      nbAvis
      url
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganisateur = /* GraphQL */ `
  mutation UpdateOrganisateur(
    $input: UpdateOrganisateurInput!
    $condition: ModelOrganisateurConditionInput
  ) {
    updateOrganisateur(input: $input, condition: $condition) {
      id
      nom
      publications {
        nextToken
      }
      note
      nbAvis
      url
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganisateur = /* GraphQL */ `
  mutation DeleteOrganisateur(
    $input: DeleteOrganisateurInput!
    $condition: ModelOrganisateurConditionInput
  ) {
    deleteOrganisateur(input: $input, condition: $condition) {
      id
      nom
      publications {
        nextToken
      }
      note
      nbAvis
      url
      createdAt
      updatedAt
    }
  }
`;
export const createProcessController = /* GraphQL */ `
  mutation CreateProcessController(
    $input: CreateProcessControllerInput!
    $condition: ModelProcessControllerConditionInput
  ) {
    createProcessController(input: $input, condition: $condition) {
      id
      name
      jsonState
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const updateProcessController = /* GraphQL */ `
  mutation UpdateProcessController(
    $input: UpdateProcessControllerInput!
    $condition: ModelProcessControllerConditionInput
  ) {
    updateProcessController(input: $input, condition: $condition) {
      id
      name
      jsonState
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const deleteProcessController = /* GraphQL */ `
  mutation DeleteProcessController(
    $input: DeleteProcessControllerInput!
    $condition: ModelProcessControllerConditionInput
  ) {
    deleteProcessController(input: $input, condition: $condition) {
      id
      name
      jsonState
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const createDocumentHtml = /* GraphQL */ `
  mutation CreateDocumentHtml(
    $input: CreateDocumentHtmlInput!
    $condition: ModelDocumentHtmlConditionInput
  ) {
    createDocumentHtml(input: $input, condition: $condition) {
      id
      modelId
      events {
        nextToken
      }
      content
      HtmlCategory
      createdAt
      updatedAt
    }
  }
`;
export const updateDocumentHtml = /* GraphQL */ `
  mutation UpdateDocumentHtml(
    $input: UpdateDocumentHtmlInput!
    $condition: ModelDocumentHtmlConditionInput
  ) {
    updateDocumentHtml(input: $input, condition: $condition) {
      id
      modelId
      events {
        nextToken
      }
      content
      HtmlCategory
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocumentHtml = /* GraphQL */ `
  mutation DeleteDocumentHtml(
    $input: DeleteDocumentHtmlInput!
    $condition: ModelDocumentHtmlConditionInput
  ) {
    deleteDocumentHtml(input: $input, condition: $condition) {
      id
      modelId
      events {
        nextToken
      }
      content
      HtmlCategory
      createdAt
      updatedAt
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      eventId
      cycleId
      clientId
      sandboxId
      lemonwayId
      lemonwayCardId
      lemonwayExpiration
      lemonwayMaskedNumber
      lemonwayType
      status
      promoStatus
      scheduledTask
      scheduledDate
      createDate
      createdAt
      updatedAt
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      eventId
      cycleId
      clientId
      sandboxId
      lemonwayId
      lemonwayCardId
      lemonwayExpiration
      lemonwayMaskedNumber
      lemonwayType
      status
      promoStatus
      scheduledTask
      scheduledDate
      createDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      eventId
      cycleId
      clientId
      sandboxId
      lemonwayId
      lemonwayCardId
      lemonwayExpiration
      lemonwayMaskedNumber
      lemonwayType
      status
      promoStatus
      scheduledTask
      scheduledDate
      createDate
      createdAt
      updatedAt
    }
  }
`;
export const createMessageContent = /* GraphQL */ `
  mutation CreateMessageContent(
    $input: CreateMessageContentInput!
    $condition: ModelMessageContentConditionInput
  ) {
    createMessageContent(input: $input, condition: $condition) {
      id
      category
      content
      hash
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageContent = /* GraphQL */ `
  mutation UpdateMessageContent(
    $input: UpdateMessageContentInput!
    $condition: ModelMessageContentConditionInput
  ) {
    updateMessageContent(input: $input, condition: $condition) {
      id
      category
      content
      hash
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageContent = /* GraphQL */ `
  mutation DeleteMessageContent(
    $input: DeleteMessageContentInput!
    $condition: ModelMessageContentConditionInput
  ) {
    deleteMessageContent(input: $input, condition: $condition) {
      id
      category
      content
      hash
      createdAt
      updatedAt
    }
  }
`;
export const createSendMessage = /* GraphQL */ `
  mutation CreateSendMessage(
    $input: CreateSendMessageInput!
    $condition: ModelSendMessageConditionInput
  ) {
    createSendMessage(input: $input, condition: $condition) {
      id
      messageContentId
      destFormateurId
      destFormateurTag
      destClientId
      destClientTag
      priority
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const updateSendMessage = /* GraphQL */ `
  mutation UpdateSendMessage(
    $input: UpdateSendMessageInput!
    $condition: ModelSendMessageConditionInput
  ) {
    updateSendMessage(input: $input, condition: $condition) {
      id
      messageContentId
      destFormateurId
      destFormateurTag
      destClientId
      destClientTag
      priority
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const deleteSendMessage = /* GraphQL */ `
  mutation DeleteSendMessage(
    $input: DeleteSendMessageInput!
    $condition: ModelSendMessageConditionInput
  ) {
    deleteSendMessage(input: $input, condition: $condition) {
      id
      messageContentId
      destFormateurId
      destFormateurTag
      destClientId
      destClientTag
      priority
      expiry
      createdAt
      updatedAt
    }
  }
`;
