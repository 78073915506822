import React, { createContext, useReducer } from 'react'
import { filtresSourceIndexed } from '../helpers/filters'
import md5 from 'md5'
import { fetchClientAttributes } from '../helpers/fetchers'

const AUTH_STATE_CHANGED = "AUTH_STATE_CHANGED"
const SET_LOGIN_PHASE = "SET_LOGIN_PHASE"
const SET_CARDS = "SET_CARDS"
const SET_PARSED = "SET_PARSED"
const TOGGLE_SUBSCRIPTION = "TOGGLE_SUBSCRIPTION"
const TOGGLE_COOKIES = "TOGGLE_COOKIES"
const SET_COOKIE = "SET_COOKIE"
const TOGGLE_LOGIN = "TOGGLE_LOGIN"
const TOGGLE_USERMENU = "TOGGLE_USERMENU"
const IMAGE_CACHE_ADD = "IMAGE_CACHE_ADD"
const TOGGLE_FILTERS = 'TOGGLE_FILTERS'
const RESET_FILTRE_TOPIC = 'RESET_FILTRE_TOPIC'
const SET_FILTRE_TOPIC = 'SET_FILTRE_TOPIC'
const SET_GENERAL_TOPIC = 'SET_GENERAL_TOPIC'
const SET_FILTRE_DATE = 'SET_FILTRE_DATE'
const SET_TRANSACTIONS = 'SET_TRANSACTIONS'
const ERASE_TRANSACTIONS = 'ERASE_TRANSACTIONS'
const APPEND_TRANSACTIONS = 'APPEND_TRANSACTIONS'
const SET_CONSENTDATA = 'SET_CONSENTDATA'
const SET_EVENTS = 'SET_EVENTS'
const SET_SLUG = 'SET_SLUG'
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
const SET_HASNEVERBOUGHT = 'SET_HASNEVERBOUGHT'
const SET_CREDITS = 'SET_CREDITS'
const SET_CODEPROMOS = 'SET_CODEPROMOS'
const SET_REPLAYS = 'SET_REPLAYS'
const APPEND_REPLAYS = 'APPEND_REPLAYS'

const UPDATE_EVENTS = 'UPDATE_EVENTS'





//connecion status
const UNKNOWN = "UNKNOWN"
export const CONNECTED = "CONNECTED"
export const FAILED = "FAILED"
export const nullUser = { connexionStatus: UNKNOWN }


const AuthContext = createContext()
const initialState = {
    user: nullUser,
    codePromos: [],
    loginPhase: 0,
    events: null,
    parsed: {},
    showSubscriptionModal: false,
    showLoginModal: 0,
    cookies: null,
    showUserMenu: false,
    showCookiesModal: false,
    consentData: null,
    imagesCache: [],
    filtres: {},
    filtreDate: null,
    filtersSwitch: false,
    transactions: [],
    replays: [],
    currentSlug: '/',
    lemonwayData: {
        cards: []
    }

}

const reducer = (state, action) => {

    switch (action.type) {
        case AUTH_STATE_CHANGED:

            return {
                ...state,
                user: action.payload,
            }
        case SET_CARDS:
            return {
                ...state,
                lemonwayData: {
                    ...state.lemonwayData,
                    cards: action.payload
                },
            }

        case SET_PARSED:

            const parsed = action.payload

            let newParsed = { ...action.payload, valid: false }
            if (parsed) {

                //const shareKey = parsed.shareKey


                const signature = parsed.signature
                const shareKey = parsed.shareKey
                const clientId = parsed.clientId



                if (md5('Signature!' + shareKey) == signature) {
                    //const fetchResult = fetchClientAttributes(shareKey, clientId)
                    newParsed = { ...action.payload, valid: true }
                    //newParsed = { ...action.payload, valid: true, fetchResult: fetchResult }

                }




            }


            return {
                ...state,
                parsed: newParsed

            }

        case SET_CODEPROMOS:

            console.log('SET_CODEPROMOS')
            console.log(state)
            return {
                ...state,
                codePromos: action.payload
            }

        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            }

        case SET_HASNEVERBOUGHT:
            return {
                ...state,
                hasNeverBought: action.payload
            }

        case SET_SLUG:
            return {
                ...state,
                slug: action.payload
            }


        case APPEND_TRANSACTIONS:


            return {
                ...state,
                transactions: state.transactions.concat(action.payload),
            }
        case SET_TRANSACTIONS:



            return {
                ...state,
                transactions: action.payload,
            }
        case SET_REPLAYS:




            return {
                ...state,
                replays: action.payload
                    .sort((a, b) => a.date - b.date)
                    .sort((a, b) => b.id - a.id)

            }
        case APPEND_REPLAYS:

            return {
                ...state,
                replays: state.replays.concat(action.payload)
                    .sort((a, b) => a.date - b.date)
                    .sort((a, b) => b.id - a.id)
            }
        case SET_CREDITS:

            console.log('@SET_CREDITS')
            console.log(state)

            return {
                ...state,
                credits: action.payload,
            }
        case SET_EVENTS:


            return {
                ...state,
                events: action.payload,
            }

        case UPDATE_EVENTS:

            const ids = action.payload.map(event => event.id)

            return {
                ...state,
                events: state.events.map(
                    event => {
                        const newEvent = action.payload.find(loopEvent => loopEvent.id === event.fields.eventId)

                        return ids.includes(event.fields.eventId) ? { ...event, ...newEvent } : event
                    }
                ),
            }
        case SET_CONSENTDATA:



            return {
                ...state,
                consentData: action.payload,
            }

        case IMAGE_CACHE_ADD:

            return {
                ...state,
                imagesCache: [...state.imagesCache, action.payload]
                // {
                //  ...state.imagesCache,
                //  cards: action.payload
                // },
            }
        case SET_COOKIE:

            return {
                ...state,
                cookies: action.payload
            }
        case TOGGLE_FILTERS:
            return {
                ...state,
                filtersSwitch: !state.filtersSwitch
            }

        case SET_FILTRE_DATE:
            return {
                ...state,
                filtreDate: action.payload
            }

        case SET_FILTRE_TOPIC:
            const topicFiltre = state.filtres[action.payload.filterItem] ^ (Math.pow(2, 15 - action.payload.index))

            return {
                ...state,
                filtres: {
                    ...state.filtres,
                    [action.payload.filterItem]: topicFiltre
                }
            }
        case RESET_FILTRE_TOPIC:
            let resetFiltre = null
            if (action.payload.index.length) {
                action.payload.index.forEach(key => {
                    resetFiltre = resetFiltre | (Math.pow(2, 15 - key))
                })
            }
            else {
                resetFiltre = (Math.pow(2, 15 - action.payload.index))

            }

            if (action.payload.index === 888)
                return {
                    ...state,
                    filtres: {
                        ...state.filtres,
                        [action.payload.filterItem]: 0
                    }
                }           // reset
            else {
                return {
                    ...state,
                    filtres: {
                        ...state.filtres,
                        [action.payload.filterItem]: resetFiltre
                    }
                }
            }
        case SET_LOGIN_PHASE:
            return {
                ...state,
                loginPhase: action.payload,
            }
        case TOGGLE_SUBSCRIPTION:


            return {
                ...state,
                showSubscriptionModal: action.payload ? action.payload : null,
            }
        case TOGGLE_COOKIES:
            return {
                ...state,
                showCookiesModal: !state.showCookiesModal,
            }
        case TOGGLE_LOGIN:
            let newValue = !state.showLoginModal

            if (action.payload === 1)
                newValue = 1
            if (action.payload === 2)
                newValue = 2




            return {
                ...state,
                showLoginModal: newValue,
            }
        case TOGGLE_USERMENU:
            return {
                ...state,
                showUserMenu: !state.showUserMenu,
            }
        default:
            { }
    }
    return state
}



const AuthProvider = (props) => {



    const [authState, dispatch] = useReducer(reducer, initialState);

    const actions = {
        setUserData: (user) => {

            if (user) {
                dispatch({ type: AUTH_STATE_CHANGED, payload: user });
            }
        },
        setTransactions: (transactions) => {

            if (transactions?.length >= 0) {
                dispatch({ type: SET_TRANSACTIONS, payload: transactions });
            }
        },
        setCredits: (credits) => {

         
            if (credits?.length >= 0) {
                dispatch({ type: SET_CREDITS, payload: credits });
            }
        },
        appendTransactions: (transactions) => {

            if (transactions?.length >= 0) {
                dispatch({ type: APPEND_TRANSACTIONS, payload: transactions });
            }
        },

        setEvents: (events) => {

            if (events?.length >= 0) {
                dispatch({ type: SET_EVENTS, payload: events });
            }
        },
        updateEvents: (events) => {

            if (events?.length >= 0) {
                dispatch({ type: UPDATE_EVENTS, payload: events });
            }
        },

        setNotifications: (notifications) => {

            if (notifications?.length >= 0) {
                dispatch({ type: SET_NOTIFICATIONS, payload: notifications });
            }
        },
        setSlug: (slug) => {

            dispatch({ type: SET_SLUG, payload: slug });
        },

        setParsed: (parsed) => {

            dispatch({ type: SET_PARSED, payload: parsed });
        },


        addImageToCache: (url) => {
            dispatch({ type: IMAGE_CACHE_ADD, payload: url });

        },
        toggleFilter: () => {
            dispatch({ type: TOGGLE_FILTERS, payload: null });

        },

        toggleSubscriptionModal: (event) => {
            dispatch({ type: TOGGLE_SUBSCRIPTION, payload: event });

        },
        setCards: (cards) => {
            dispatch({ type: SET_CARDS, payload: cards });

        },
        toggleTopic: (payload) => {
            dispatch({ type: SET_FILTRE_TOPIC, payload: payload });

        },
        resetTopic: (payload) => {
            dispatch({ type: RESET_FILTRE_TOPIC, payload: payload });

        },
        toggleCookiesModal: () => {
            dispatch({ type: TOGGLE_COOKIES, payload: null });

        },
        setCookies: (cookies) => {
            dispatch({ type: SET_COOKIE, payload: cookies });

        },
        setConsentData: (consentData) => {
            dispatch({ type: SET_CONSENTDATA, payload: consentData });

        },
        setFiltreDate: (date) => {
            dispatch({ type: SET_FILTRE_DATE, payload: date });

        },

        set_HasNeverBought: (state) => {
            dispatch({ type: SET_HASNEVERBOUGHT, payload: state });

        },

        toggleLoginModal: (value) => {
            dispatch({ type: TOGGLE_LOGIN, payload: value });

        },
        setLoginPhase: (phase) => {
            dispatch({ type: TOGGLE_LOGIN, payload: phase });

        },
        setCredits: (credits) => {
            dispatch({ type: SET_CREDITS, payload: credits });

        },
        setCodePromos: (promos) => {
            dispatch({ type: SET_CODEPROMOS, payload: promos });

        },
        setReplays: (replays) => {
            dispatch({ type: SET_REPLAYS, payload: replays });

        },
        appendReplays: (replays) => {


            dispatch({ type: APPEND_REPLAYS, payload: replays });

        },
        toggleUserMenu: () => {

            dispatch({ type: TOGGLE_USERMENU, payload: !authState.showUserMenu });

        }
    }


    return (
        <AuthContext.Provider
            value={{
                authState: authState,
                authActions: actions,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}

export { AuthProvider, AuthContext }

export default ({ element }) => (
    <AuthProvider>
        {element}
    </AuthProvider>
)