
import Provider from './src/context/general'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js"
// import Amplify, { Auth } from 'aws-amplify'
// import awsConfig from './src/aws-exports'
// Amplify.configure(awsConfig)

const scrollToTopRoutes = [`/arts/`, `/découvertes/`,'/digital/','/éducation/','/environnement/','/','//','/sport/','/histoire/','/musique/','/philosophie/','/sciences/']
   

export const onClientEntry = () => {

  //callAnalyticsAPI()
}



// in gastby-browser.js
export const shouldUpdateScroll = ({




   routerProps,
   getSavedScrollPosition,
 }) => {
  
   const { state, pathname } = routerProps.location


   const slug = state?.from




   if (pathname.includes('conference') || pathname.includes('formateur') || pathname.includes('cycle'))
    return true
   
  return false


  
   // list of routes for the scroll-to-top-hook
   // if the new route is part of the list above, scroll to top (0, 0)
   if ((scrollToTopRoutes.indexOf(decodeURIComponent(pathname)+'/') !== -1) && (scrollToTopRoutes.indexOf(decodeURIComponent(slug)) !== -1)) {
       return false
   }
 
   return true
 }


 
export const wrapRootElement = Provider;