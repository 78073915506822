import { API, graphqlOperation } from "aws-amplify"
import { clientByCognitoId, formateurByCognitoId } from '../helpers/graphql'
import { transactionsByClientId, creditsbyClient, getEvent, getCycle, emailByEmail, eventsbyCycle, notificationsByClientId, attributesbyClientId, listPromoCodes } from '../graphql/queries'
import { createEmail, updateEmail, updateEvent, updateNotification, createClientAttribute } from '../graphql/mutations'
import { createClient } from '../helpers/mutations'
import { CONNECTED, FAILED } from '../context/general'
import { v4 as uuid_v4 } from "uuid"
import publicIp from 'public-ip'



export const handleNewMail = (email) => {

    console.log('@ HANDLE EMAIL')

    API.graphql(graphqlOperation(emailByEmail, { email: email }))
        .then(({ data }) => {
            if (!data.emailByEmail.items.length) {

                const input = { email: email, pekoiaClient: false }

                API.graphql(graphqlOperation(createEmail, { input: input }))
                    .then(data => { })
                    .catch(err => console.log(err))
            }

            else {

                const emails = data.emailByEmail.items
                emails.forEach(email => {

                    const input = { id: email.id, suscribedStatus: 'SUSCRIBED' }

                    API.graphql(graphqlOperation(updateEmail, { input: input }))
                        .then(data => { })
                        .catch(err => console.log(err))



                })




            }




        })

}




export const fetchTransactions = (cognitoId, setTransactions, setCards, set_HasNeverBought, appendTransactions, setCredits, setReplays, appendReplays) => {




    API.graphql(graphqlOperation(clientByCognitoId, { cognitoId: cognitoId }))
        .then(({ data }) => {

           
            if (data.clientByCognitoId.items) {

                const clientId = data.clientByCognitoId.items[0].id

                API.graphql(graphqlOperation(creditsbyClient, { clientId: clientId, limit: 100000000 }))
                    .then(({ data }) => {

                        setCredits(data.creditsbyClient.items)


                        // console.log('@CREDITS')
                        // console.log(data)

                    })

                API.graphql(graphqlOperation(transactionsByClientId, { clientId: clientId, limit: 100000000 }))
                    .then(({ data }) => {

                        if (data.transactionsByClientId.items) {

                            const transactionsData = data.transactionsByClientId.items
                                .filter(transaction => transaction.lemonwayId && transaction.status === 'SETTLED')



                            const allTransactionsData = data.transactionsByClientId.items
                                .filter(tx => tx.lemonwayCardId)
                                .sort((a, b) => {
                                    const dateA = new Date(a.createdAt)
                                    const dateB = new Date(b.createdAt)
                                    return (dateB.getTime() - dateA.getTime())

                                })


                            const potentialTxs = data.transactionsByClientId.items
                                .filter(transaction => transaction.lemonwayId && transaction.status === 'SETTLED' || transaction.status === 'PROCESSING')


                            if (potentialTxs.length == 0) {
                                set_HasNeverBought(true)
                            }



                            setCards(allTransactionsData)
                            setReplays([])

                            const CyclesDBqueries = transactionsData
                                .filter(transaction => transaction.cycleId != 'undetermined')
                                .map(transaction => transaction.cycleId)
                                .map(cycleId => API.graphql(graphqlOperation(getCycle, { id: cycleId })))


                            Promise.all(CyclesDBqueries)
                                .then(result => {

                                  


                                    const cycleIds = result
                                    .filter(cycle => cycle.data.getCycle)
                                        .map(cycle => cycle.data.getCycle.id)

                                    //eventsbyCycle
                                    const cycleFetches = []
                                    cycleIds.forEach(cycleId => {


                                        const newFetch = API.graphql(graphqlOperation(eventsbyCycle, { cycleId: cycleId }))
                                        cycleFetches.push(newFetch)

                                    })

                                    Promise.all(cycleFetches)
                                        .then(result => {


                                         



                                            result.forEach(cycle => {
                                                const replaysRaw = cycle.data.eventsbyCycle.items
                                                    .filter(elem => elem.replayCategory)

                                               

                                                const replaysIds = []
                                                const replays = []
                                                replaysRaw.forEach(elem => {
                                                    if (!replaysIds.includes(elem.id)) {
                                                        replays.push(elem)
                                                        replaysIds.push(elem.id)
                                                    }

                                                })

                                            
                                        
                                                appendReplays(replays)


                                                appendTransactions(cycle.data.eventsbyCycle.items)
                                            })

                                        })

                                })




                            const DBqueries = transactionsData
                                .filter(transaction => transaction.eventId != 'undetermined')
                                .filter(transaction => (transaction.status === 'SETTLED' || transaction.status === 'PROCESSING'))
                                .map(transaction => transaction.eventId)
                                .map(eventId => API.graphql(graphqlOperation(getEvent, { id: eventId })))

                            Promise.all(DBqueries)
                                .then((data) => {

                                    setTransactions(data.map(elem => elem.data.getEvent))

                                    console.log('--DEBUG--')
                                    console.log(data)

                                    const replaysRaw = data
                                        .map(elem => elem.data.getEvent)
                                        .filter(elem => elem?.replayCategory)

                                    const replaysIds = []
                                    const replays = []
                                    replaysRaw.forEach(elem => {
                                        if (!replaysIds.includes(elem.id)) {
                                            replays.push(elem)
                                            replaysIds.push(elem.id)
                                        }

                                    })

                                    appendReplays(replays)


                                })
                                .catch((err) => console.log(err))

                        }

                    })
                    .catch(err => console.log(err)
                    )

            }
        })
        .catch(err => console.log(err))


}


export const fetchPromoCodes =  (setPromos) => {

  
    if (!setPromos)
        return null

   
    API.graphql(graphqlOperation(listPromoCodes, {
            filter: {
                id : {
                    ne : ""
                }
            }
        }
    ))
        .then(({ data }) => {
           
            setPromos(data.listPromoCodes.items)



        })
        .catch(err => console.log(err))




   

}

export const fetchCredits = (cognitoId, setCredits) => {

    if (!setCredits)
        return null

    console.log('@FETCHING CREDITS')

    API.graphql(graphqlOperation(clientByCognitoId, { cognitoId: cognitoId }))
        .then(({ data }) => {
            if (data.clientByCognitoId.items) {
                const clientId = data.clientByCognitoId.items[0].id
                API.graphql(graphqlOperation(creditsbyClient, { clientId: clientId, limit: 100000000 }))
                    .then(({ data }) => {

                        setCredits(data.creditsbyClient.items)

                    })
                    .catch(err => console.log(err))
            }



        })
        .catch(err => console.log(err))




    // API.graphql(graphqlOperation(clientByCognitoId, { cognitoId: cognitoId }))
    //     .then(({ data }) => {

    //         if (data.clientByCognitoId.items) {

    //             const clientId = data.clientByCognitoId.items[0].id

    //             API.graphql(graphqlOperation(transactionsByClientId, { clientId: clientId, limit: 100000000  }))
    //                 .then(({ data }) => {

    //                     if (data.transactionsByClientId.items) {
    //                         console.log('@FETCHING TX BY CLIENT ID')





    //                         const transactionsData = data.transactionsByClientId.items
    //                             .filter(transaction => transaction.lemonwayId && transaction.status === 'SETTLED')



    //                         const allTransactionsData = data.transactionsByClientId.items
    //                             .filter(tx => tx.lemonwayCardId)
    //                             .sort((a, b) => {
    //                                 const dateA = new Date(a.createdAt)
    //                                 const dateB = new Date(b.createdAt)
    //                                 return (dateB.getTime() - dateA.getTime())

    //                             })



    //                         setCards(allTransactionsData)

    //                         const CyclesDBqueries = transactionsData
    //                             .filter(transaction => transaction.cycleId != 'undetermined')
    //                             .map(transaction => transaction.cycleId)
    //                             .map(cycleId => API.graphql(graphqlOperation(getCycle, { id: cycleId })))


    //                         Promise.all(CyclesDBqueries)
    //                             .then(result => {

    //                                 console.log('@FETCHING CYCLES')

    //                                 const cycleIds = result
    //                                     .map(cycle => cycle.data.getCycle.id)

    //                                 //eventsbyCycle
    //                                 const cycleFetches = []
    //                                 cycleIds.forEach(cycleId => {
    //                                     const newFetch = API.graphql(graphqlOperation(eventsbyCycle, { cycleId: cycleId }))
    //                                     cycleFetches.push(newFetch)

    //                                 })

    //                                 Promise.all(cycleFetches)
    //                                     .then(result => {


    //                                         result.forEach(cycle => appendTransactions(cycle.data.eventsbyCycle.items))

    //                                     })



    //                             })








    //                         const DBqueries = transactionsData
    //                             .filter(transaction => transaction.eventId != 'undetermined')
    //                             .filter(transaction => (transaction.status === 'SETTLED' ||  transaction.status === 'PROCESSING'))
    //                             .map(transaction => transaction.eventId)
    //                             .map(eventId => API.graphql(graphqlOperation(getEvent, { id: eventId })))

    //                         Promise.all(DBqueries)
    //                             .then((data) => {

    //                                 setTransactions(data.map(elem => elem.data.getEvent))
    //                             })
    //                             .catch((err) => console.log(err))

    //                     }

    //                 })
    //                 .catch(err => console.log(err)
    //                 )

    //         }
    //     })
    //     .catch(err => console.log(err))


}


// *****

export const setNotificationsAsViewed = (notificationId) => {

    const input = {
        id: notificationId,
        state: 'NOTIFIED'

    }

    API.graphql(graphqlOperation(updateNotification, { input: input }))
        .then(_ => {
            console.log('@setNotificationsAsViewed')

        })
        .catch(err => console.log(err))

}



export const fetchNotifications = (clientId, setNotifications) => {

    API.graphql(graphqlOperation(notificationsByClientId, { clientId: clientId }))
        .then(({ data }) => {
            const notifications = data.notificationsByClientId.items
                                   

   

            setNotifications(notifications)
        })
        .catch(err => console.log(err))

}

export const fetchCreateClientAttribute = (input) => {

    const result = API.graphql(graphqlOperation(createClientAttribute, { input: input }))
        .then(data => {
            console.log('@DATA CREATE')
            console.log(data)
        })
        .catch(err => console.log(err))


}

export const fetchClientAttributes = (shareKey, clientId, callback) => {



    let result

    if (clientId) {

        result = API.graphql(graphqlOperation(attributesbyClientId, { clientId: shareKey }))
            .then(({ data }) => {




                const items = data.attributesbyClientId?.items
                    .filter(attribute => attribute.state == 'PROCESSING')
                    .filter(attribute => attribute.key == 'INV')
                    .filter(attribute => attribute.value == clientId)
                const promoAttributes = items



                return promoAttributes.length == 0 ? true : false

            })
            .catch(err => {
                console.log('@FETCH ERR')
                console.log(err)
            })
    }

    return result

}



export const fetchUserData = (userData, setUserData, count, consent = false) => {

    let totalCount = !count ? 0 : count




    if (userData) {





        const cognitoId = userData.attributes.sub



        API.graphql(graphqlOperation(clientByCognitoId, { cognitoId: cognitoId }))
            .then(({ data }) => {


                const requestItems = data.clientByCognitoId.items

                if (requestItems.length > 0) {


                    // load data in formateur Table formateurByCognitoId
                    API.graphql(graphqlOperation(formateurByCognitoId, { cognitoId: cognitoId }))
                        .then(({ data: formateurData }) => {
                            let prenom = null
                            let nom = null


                            if (formateurData.formateurByCognitoId.items.length) {
                                prenom = formateurData.formateurByCognitoId.items[0].prenom ? formateurData.formateurByCognitoId.items[0].prenom : null

                                nom = formateurData.formateurByCognitoId.items[0].nom ? formateurData.formateurByCognitoId.items[0].nom : null

                            }

                            setUserData({ ...requestItems[0], nom: nom, prenom: prenom, connexionStatus: CONNECTED, attributes: userData.attributes })  //{ sub: cognitoId } 


                        })


                }
                else if (totalCount < 3) {
                    const clientID = uuid_v4()
                    let input = { id: clientID, cognitoId: cognitoId, CGUVersion: 'v1.0-23062021' }
                    if (consent)
                        input = { ...input, newsletterConsentDate: Math.floor((new Date()).getTime() / 1000) }

                    const email = userData.attributes?.email
                    const email_verified = userData.attributes?.email_verified



                    if (email_verified) {
                        API.graphql(graphqlOperation(createClient, { input: input }))
                            .then(({ data }) => {



                                const input = { pekoiaClient: true, clientId: data.createClient.id, email: email }


                                API.graphql(graphqlOperation(createEmail, { input: input }))
                                    .then(data => {


                                        fetchUserData(userData, setUserData, totalCount + 1)








                                    })
                                    .catch(err => console.log(err))




                            })
                    }
                    else {
                        fetchUserData(userData, setUserData, totalCount + 1)
                    }



                }
                else {
                    setUserData({ ...requestItems[0], connexionStatus: FAILED })
                }

            })
            .catch(err => console.log(err))
    }

}

export const handleFreeSubscription = (eventId) => {

    API.graphql(graphqlOperation(getEvent, { id: eventId }))
        .then(({ data }) => {

            if (data.getEvent) {

                let attendingNb = 1
                if (data.getEvent.attendingNb)
                    attendingNb = data.getEvent.attendingNb + 1
                const input = { id: eventId, attendingNb: attendingNb }
                API.graphql(graphqlOperation(updateEvent, { input: input }))


                    .then(({ data }) => {

                    })
                    .catch(err => console.log(err))


                //const input = { email: email, pekoiaClient: false }

                // API.graphql(graphqlOperation(createEmail, { input: input }))
                // .then(data=>{})
                // .catch(err=>console.log(err))
            }
        })

}




const encrypt = (data) => {




    return btoa(data)
};

export const greet = (id) => {

    publicIp.v4()
        .then(ip => {

            const input = {
                clientId: id,
                key: encrypt(ip)
            }

            const result = API.graphql(graphqlOperation(createClientAttribute, { input: input }))
                .then(data => {

                })
                .catch(err => console.log(err))



        })
}






