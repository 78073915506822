export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      cognitoId
      prenom
      nom
      sanboxLemonwayId
      createdAt
      updatedAt
    }
  }
`;


export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      cognitoId
      prenom
      nom
      sanboxLemonwayId
      createdAt
      updatedAt
    }
  }
`;