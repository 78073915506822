import getDate from './dates'
import { DateTime } from 'luxon'

const FILTRE_ART = 'arts'
const FILTRE_DEV = 'développement personnel'
const FILTRE_HISTOIRE = 'histoire'
const FILTRE_GEOPOLIQUE = 'géopolitique'
const FILTRE_PHILO = 'philosophie'
const FILTRE_LITTERATURE = 'littérature'
const FILTRE_GEOGRAPHIE = 'géographie'
const FILTRE_DECOUVERTE = 'découverte'
const FILTRE_SCIENCES = 'sciences'
const FILTRE_ECOLOGIE = 'écologie'
const FILTRE_LANGUES = 'langues'
const FILTRE_NUMERIQUE = 'numérique'
const FILTRE_MUSIQUE = 'musique'
const FILTRE_SOCIALES = 'sciences sociales'
const FILTRE_SPORT = 'sports'
const FILTRE_LOISIRS = 'loisirs'


// return a new string with the first letter in Uppercase
function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


// do not change order of elements as the position is used for filterting later
export const indexedFiltres = [
  FILTRE_ART,
  FILTRE_DEV,
  FILTRE_HISTOIRE,
  FILTRE_GEOPOLIQUE,
  FILTRE_PHILO,
  FILTRE_LITTERATURE,
  FILTRE_GEOGRAPHIE,
  FILTRE_DECOUVERTE,
  FILTRE_SCIENCES,
  FILTRE_ECOLOGIE,
  FILTRE_LANGUES,
  FILTRE_NUMERIQUE,
  FILTRE_MUSIQUE,
  FILTRE_SOCIALES,
  FILTRE_SPORT,
  FILTRE_LOISIRS
]


// this is the mapping of the filter buttons . It can be modified
const filtresSource = [
  [FILTRE_ART, FILTRE_MUSIQUE, FILTRE_LITTERATURE],
  [FILTRE_HISTOIRE, FILTRE_GEOPOLIQUE, FILTRE_SOCIALES, FILTRE_PHILO],
  [FILTRE_ECOLOGIE, FILTRE_SCIENCES, FILTRE_GEOGRAPHIE, FILTRE_DECOUVERTE],
  [FILTRE_DEV, FILTRE_LANGUES, FILTRE_NUMERIQUE, FILTRE_SPORT, FILTRE_LOISIRS]

]

export const filtresSourceIndexed = filtresSource.map(
  filtreArray => filtreArray.map(filtre => indexedFiltres.indexOf(filtre))
)



// order mmust be consistent with previous array
export const filtresSlug = [
  '/arts',
  '/éducation',
  '/histoire',
  '/philosophie',
  '/découvertes',
  '/environnement',
  '/digital',
  '/musique',
  '/sciences',
  '/sport'
]

const filtres = filtresSource
  .map((filtre, index) => {

    let filterCode = 0
    filtre.forEach(subFilter => {
      const index = indexedFiltres.indexOf(subFilter)

      filterCode = filterCode | Math.pow(2, index)

    });
    const label = filtre
      .map(item => jsUcfirst(item))
      .join(' & ')

    return ({
      label: label,
      categoriesCode: filterCode,
      slug: filtresSlug[index]
    })

  })

  export const filterReplaysOnCategory = (events, catégorieSortKey) => {

    const now = DateTime.local()

    return events
         .filter(event => ((catégorieSortKey === (Math.pow(2, 16) - 1)) || !event.categoriesCode || event.categoriesCode & catégorieSortKey))
      //   .filter(event => {
       
      //     if ((getDate(event.date).luxonDate >= now) )
      //         return true
      //     else return false
      // })
    
}

  export const filterEventsOnCategory = (events, catégorieSortKey) => {

    const now = DateTime.local()

    return events
        .filter(event => event.children)
        .filter(event => event.children.find(child => child?.original))
        .filter(event => event.children.find(child => child?.state === 'published' || child?.state === 'PREPUBLISHED'))
        .sort((a, b) => a.date - b.date)
       // .filter(event => ((catégorieSortKey === (Math.pow(2, 16) - 1)) || !event.categoriesCode || event.categoriesCode & catégorieSortKey))
        .filter(event => {
       
          if ((getDate(event.date).luxonDate >= now) )
              return true
          else return false
      })
    
}

  export const filterEvents = (events, catégorieSortKey, now, startDate) => {



    return events
        .filter(event => now <= getDate(event.date).luxonDate)
        .filter(event => event.children)
        .filter(event => event.children.find(child => child.original))
        .filter(event => event.children.find(child => child.state === 'published' || child.state === 'PREPUBLISHED'))
        .sort((a, b) => a.date - b.date)
      //  .filter(event => ((catégorieSortKey === (Math.pow(2, 16) - 1)) || !event.categoriesCode || event.categoriesCode & catégorieSortKey))
        .filter(event => {
            if (!startDate)
                return true
            else if ((getDate(event.date).luxonDate >= startDate) && (getDate(event.date).luxonDate <= startDate.plus({ days: 7 })))
                return true
            else return false
        })
    
}


  export const filterButtonsFrameWork = [
    // {
    //   position: 0,
    //   label: 'Format',
    //   choices: ['atelier', 'classe virtuelle', 'webinar'],
    //   filterItem: 'format'
    // },
    {
      position: 0,
      label: 'Date',
      choices: ['cette semaine', 'ce week-end', 'la semaine prochaine', 'le week-end prochain'],
      filterItem: 'date'
    },
    {
      position: 1,
      label: 'Catégorie',
      choices: indexedFiltres,
      filterItem: 'catégorie'
    },
    {
      position: 2,
      label: 'Niveau',
      choices: ['tous niveaux', 'débutant', 'intermédiaire', 'expert'],
      filterItem: 'niveau'
    }
  ]


  export const eventFormat = [
    'ATELIER', 'CLASSE', 'WEBINAR'
  ]




