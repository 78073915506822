import {DateTime} from 'luxon'


 const getDate = (epoch) => {

    const dt = DateTime.fromISO('1970-01-01T00:00:00Z')
    const newDate = dt.plus({ seconds: epoch })

    
    
    return ({
        luxonDate: newDate,
        time: newDate.toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false }),
        date: newDate.setLocale('fr-FR').toLocaleString(DateTime.DATE_MED).replace('.',''),
        ParisTime: newDate.setZone("Europe/Paris").toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false }),
        ParisDate: newDate.setZone("Europe/Paris").setLocale('fr-FR').toLocaleString(DateTime.DATE_MED).replace('.',''),
        dateShort: newDate.setLocale('fr-FR').toLocaleString(DateTime.DATE_SHORT),
        weekDay: newDate.setLocale('fr-FR').toLocaleString(DateTime.DATE_HUGE)
    })
    //const date = new Date(newDate.toISO())
    //return date
  }

  export default getDate